import { useEffect } from "react";
import { addRemoveClassBody } from "../../utils";
import {
  TokenFeaturesTop,
  TokenFeaturesMed,
  TokenFeaturesDown,
  SmartContractinfo,
  OurBlogInfo,
} from "../../data/data-containers/HomeDemo3/data-HomeDemo3.js";

import OurPatformInfo from "../../data/data-containers/HomeDemo3/data-OurPatformInfo.json";
import TokenDistributionInfo from "../../data/data-containers/HomeDemo3/data-TokenDistributionInfo.json";

import { HomeDemo3Wwhitepaper, HomeDemo3About1 } from "../../utils/allImgs";
import "./style/HomeDemo3.scss";

import Header from "../../layouts/Header";
import FooterPages from "../../layouts/Footer/FooterPages";
import SecHeroSection from "../../components/HeroSection";
import SecAbout from "../../components/SecAbout";
import FuelFeatures from "../../components/FuelFeatures";
import SpreadMap from "../../components/SpreadMap";
import SmartContract from "../../components/SmartContract";
import TokenDistribution from "../../components/TokenDistribution";
import OurBlog from "../../components/OurBlog";
import OurPlatform from "./OurPlatform";
import TokenFeatures from "./TokenFeatures";

const HomeDemo3Container = () => {
  useEffect(() => {
    addRemoveClassBody("darker");
  }, []);

  return (
    <>
      <Header />
      <SecHeroSection
        ClassSec="hero-section de-3 section-padding"
        ClassDiv="col-12 col-lg-5 col-md-12"
        specialHead="Unlocking Halal Crypto Trading"
        title="The First Halal Dex"
        link1="Learn more"
        link2="Coming Soon"
        HomeDemo1Or4Or5Or6={false}
      />
      <div className="clearfix" />
      <OurPlatform data={OurPatformInfo} ClassSpanTitle="gradient-text blue" />
      <SecAbout
        imgDwon={true}
        title="Best Swapping Experience"
        text="Swap crypto currency and refer new members to get bounes."
        img={HomeDemo3About1}
      />
      <FuelFeatures />
      <TokenFeatures
        TokenFeaturesTop={TokenFeaturesTop}
        TokenFeaturesMed={TokenFeaturesMed}
        TokenFeaturesDown={TokenFeaturesDown}
        ClassSpanTitle="gradient-text blue"
      />
      <SmartContract data={SmartContractinfo} />
      <div className="clearfix" />
      <SpreadMap Wwhitepaper={HomeDemo3Wwhitepaper} />
      {/* <TokenDistribution data={TokenDistributionInfo} ClassSpanTitle="gradient-text blue" /> */}
      {/* <OurBlog data={OurBlogInfo} ClassSpanTitle="gradient-text blue" /> */}
      <FooterPages ClassSpanTitle="gradient-text blue" />
    </>
  );
};

export default HomeDemo3Container;
