import {
  HomeDemo3FeaturesS1,
  HomeDemo3FeaturesS2,
  HomeDemo3FeaturesS3,
  HomeDemo3FeaturesS4,
  HomeDemo3FeaturesF1,
  HomeDemo3FeaturesF2,
  HomeDemo3FeaturesF3,
  HomeDemo3FeaturesF4,
  HomeDemo3FeaturesF5,
  HomeDemo3FeaturesF6,
  HomeDemo3TeamImg1,
  HomeDemo3TeamImg2,
  HomeDemo3TeamImg3,
  HomeDemo3TeamImg4,
  HomeDemo3BlogImg1,
  HomeDemo3BlogImg2,
  HomeDemo3BlogImg3,
} from "../../../utils/allImgs";

export const TokenFeaturesTop = [
  {
    img: HomeDemo3FeaturesF1,
    title: "Robust Security Measures",
    text: "Your assets are safeguarded by cutting-edge security, encryption, and regular audits for worry-free trading.",
  },
  {
    img: HomeDemo3FeaturesF2,
    title: "Educational Resources",
    text: "Access webinars and expert insights to deepen your understanding of Halal finance and crypto trading strategies.",
  },
];

export const TokenFeaturesMed = [
  {
    img: HomeDemo3FeaturesF3,
    title: "User-Friendly Interface",
    text: "Easily swap cryptocurrencies and manage your portfolio with our intuitive platform's user-friendly design.",
  },
  {
    img: HomeDemo3FeaturesF4,
    title: "Active Community Support",
    text: "Connect with like-minded traders and investors in our thriving community for shared knowledge and support.",
  },
];
export const TokenFeaturesDown = [
  {
    img: HomeDemo3FeaturesF5,
    title: "Halal-Certified Cryptocurrency Selection",
    text: "Invest confidently with our curated Halal-compliant cryptocurrency options, aligning with Islamic finance principles.",
  },
  {
    img: HomeDemo3FeaturesF6,
    title: "Transparent and Accountable",
    text: "We prioritize transparency, offering real-time data on fees, liquidity, and assets to build trust.",
  },
];

export const SmartContractinfo = [
  {
    img: HomeDemo3FeaturesS1,
    title: "Cycles and schedules",
  },
  {
    img: HomeDemo3FeaturesS2,
    title: "Advanced application interface (API)",
  },
  {
    img: HomeDemo3FeaturesS3,
    title: "Software development kit (SDK)",
  },
  {
    img: HomeDemo3FeaturesS4,
    title: "Java virtual machine (JVM)",
  },
];

export const OurTeamInfo = [
  {
    img: HomeDemo3TeamImg1,
    title: "Joman Helal",
    text: "Executive Officer",
  },
  {
    img: HomeDemo3TeamImg2,
    title: "Slans Alons",
    text: "Business Development",
  },
  {
    img: HomeDemo3TeamImg3,
    title: "Josha Michal",
    text: "UX/UI Designer",
  },
  {
    img: HomeDemo3TeamImg4,
    title: "Johan Wright",
    text: "Head of Marketing",
  },
];

export const OurBlogInfo = [
  {
    img: HomeDemo3BlogImg1,
    title: "What is this Token for?.",
  },
  {
    img: HomeDemo3BlogImg2,
    title: "The most powerful Token",
  },
  {
    img: HomeDemo3BlogImg3,
    title: "How to get trial version",
  },
];
