import { useEffect } from "react";
import Logo from "../../data/data-layout/Header/data-Logo.js";
import MenuInfo from "../../data/data-layout/Header/data-MenuInfo.json";
import { Addshrink, moveSmooth } from "../../utils/";
import "./header.css";
import SecHeader from "./SecHeader";

const Header = () => {
  useEffect(() => {
    Addshrink();
  }, []);

  useEffect(() => {
    moveSmooth();
  }, []);

  return <SecHeader Logo={Logo} MenuInfo={MenuInfo} />;
};

export default Header;
