const SpreadMap = ({ Wwhitepaper, SectionIcon11 }) => {
  return (
    <section id="whitepaper" className="spread-map download">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-xs-12">
            <div className="welcome-meter">
              <img
                draggable="false"
                src={Wwhitepaper}
                className="center-block"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-xs-12 mt-s">
            <div className="who-we-contant">
              <div className="dream-dots text-left">
                <img draggable="false" src={SectionIcon11} alt="" />
              </div>
              <h4 className="text-white">Downoad Our Whitepaper</h4>
              <p className="text-white">
                Our comprehensive whitepaper delves into the core principles of
                our Halal DEX platform, elucidating its innovative approach to
                compliant cryptocurrency trading. Discover how we're
                revolutionizing the intersection of Islamic finance and
                blockchain technology.
              </p>
              <a className="btn dream-btn mt-30" href="/#">
                Get Whitepaper
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpreadMap;
