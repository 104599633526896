import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";

import SectionHeading from "../../../components/SectionHeading";
import "../Footer.scss";
import IcoSocial from "../../../data/data-layout/Footer/data-IcoSocial.json";
import TextFooter from "../../../data/data-layout/Footer/data-TextFooter.json";
import Logo from "../../../data/data-layout/Header/data-Logo.js";

const FooterPages = ({ ClassSpanTitle = "" }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY,
      )
      .then(
        () => {
          setLoading(false);
          setIsFormSubmitted(true);
        },
        (error) => {
          setLoading(false);
          setError("Server error");
          console.log(error.text);
        },
      );
  };

  return (
    <footer className="footer-area bg-img mt-5">
      {/* ##### Contact Area Start ##### */}
      <div className="contact_us_area section-padding-0-0" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionHeading
                title=""
                text="Contact Us"
                ClassSpanTitle={ClassSpanTitle}
              />
            </div>
          </div>
          {/* Contact Form */}
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="contact_form">
                {!isFormSubmitted ? (
                  <form ref={form} onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">{error}</div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.2s">
                          <input
                            type="text"
                            name="user_name"
                            id="user_name"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Name</label>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="group fadeInUp" data-wow-delay="0.3s">
                          <input
                            type="email"
                            name="user_email"
                            id="user_email"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Email</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="group fadeInUp" data-wow-delay="0.5s">
                          <textarea
                            name="message"
                            id="message"
                            required
                            defaultValue={""}
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label>Message</label>
                        </div>
                      </div>
                      <div
                        className="col-12 text-center fadeInUp"
                        data-wow-delay="0.6s"
                      >
                        <button type="submit" className="btn more-btn">
                          {!loading ? "Send Message" : "Sending..."}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div>
                    <h3 className="col-12 text-center fadeInUp">
                      Thank you for getting in touch!
                    </h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ##### Contact Area End ##### */}
      <div className="footer-area bg-img">
        <div className="footer-content-area">
          <div className="container">
            <div className="row ">
              <div className="col-12 col-lg-4 col-md-6">
                <div className="footer-copywrite-info">
                  <div
                    className="copywrite_text fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    <div className="footer-logo">
                      <a href="/">
                        <img draggable="false" src={Logo} alt="logo" /> Halal
                        Dex
                      </a>
                    </div>
                  </div>
                  <div
                    className="footer-social-info fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    {IcoSocial &&
                      IcoSocial.map((item, key) => (
                        // eslint-disable-next-line
                        <a key={key} href={item.link} target="_blank">
                          <i className={item.IcoName} aria-hidden="true" />
                        </a>
                      ))}
                  </div>
                </div>
              </div>

              {TextFooter &&
                TextFooter.map((item, key) => (
                  <div key={key} className={item.classBlock}>
                    <div className="contact_info_area d-sm-flex justify-content-between">
                      <div className={item.classInfo} data-wow-delay="0.3s">
                        <h5>{item.title}</h5>
                        <a href="/#">
                          <p>{item.text1}</p>
                        </a>
                        <a href="/#">
                          <p>{item.text2}</p>
                        </a>
                        <a href="/#">
                          <p>{item.text3}</p>
                        </a>
                        <a href="/#">
                          <p>{item.text4}</p>
                        </a>
                        {item.text5 && (
                          <a href="/#">
                            <p>{item.text5}</p>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterPages;
