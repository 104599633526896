import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Routes, Route } from "react-router-dom";
import Aos from "aos";

import HomeDemo3Container from "./containers/HomeDemo3";
import "aos/dist/aos.css";
import "./assets/css/General.css";
// import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";
import "./assets/css/font-awesome.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<HomeDemo3Container />} />
      </Routes>
    </div>
  );
};

export default App;
