const Block = ({ img, title, text }) => {
  return (
    <div className="services-block-four v2">
      <div className="inner-box">
        <div className="icon-img-box">
          <img draggable="false" src={img} alt={title} />
        </div>
        <h3>{title}</h3>
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

export default Block;
